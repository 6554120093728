* {
	padding: 0;
	margin: 0;
}

html {
	font-size: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

a {
	text-decoration: none;
}

.tm_1 { transform: rotate(180deg) }
